import React from "react"

import Layout from "../components/Layout"
import SEO from "../components/Seo"
import PostLinks from "../components/PostList"
import { graphql } from "gatsby"

const NotFoundPage = ({ data }) => {
  const posts = data.allMdx.edges.map(edge => {
    return edge.node
  })
  return (
    <Layout>
      <SEO title="404: Not found" />
      <h1>404 NOT FOUND</h1>
      <p>This page doesnt exist but here are some posts that you may like!</p>
      <PostLinks posts={posts} />
    </Layout>
  )
}

export default NotFoundPage

export const pageQuery = graphql`
  query {
    allMdx(limit: 3) {
      edges {
        node {
          id
          excerpt
          timeToRead
          tableOfContents
          frontmatter {
            title
            excerpt
            image
            tags
          }
          fields {
            slug
            date
          }
        }
      }
    }
  }
`
